.App {
  text-align: center;
  height: 100vh;
  width: 100%;
  background-color: #222f3e;
}
.loader{
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: #222f3e;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px;
}
.modal-body{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  max-width: 1200px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.loadingScreen {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #1E1E1E;
  top: 0;
  left: 0;
  z-index: 1301;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-size: cover;
  background-position: center;
}
.fidelity-logo{
  display: block;
}
.loading-label{
  margin: 16px 0;
  font-size: 1rem;
}

.myFadeInDiv {
  animation: fadein 3s;
}

.fadeInText{
  animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}